import { useState } from "react";
import "./App.css";
import Aside from "./components/Aside";
import Header from "./components/Header";
import VideoPlayer from "./components/VideoPlayer";

function App() {
  // console.log("App");

  const [arr, setArr] = useState(
    window.screen.width > 600
      ? [generateRandomNumber(), generateRandomNumber(), generateRandomNumber()]
      : [
          generateRandomNumber(),
          generateRandomNumber(),
          generateRandomNumber(),
          generateRandomNumber(),
          generateRandomNumber(),
          generateRandomNumber(),
          generateRandomNumber(),
          generateRandomNumber(),
        ]
  );
  const [play, setPlay] = useState(generateRandomNumber());

  function generateRandomNumber() {
    const min = 0;
    const max = 1080960;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  }

  console.log("Menu:", arr, " || ", "Play:", play);

  const oldPass = JSON.parse(localStorage.getItem("love-pass"));
  if (!(oldPass && oldPass.pass === process.env.REACT_APP_PASS)) {
    let pass = prompt("Please Enter your password");
    while (!(pass && pass === process.env.REACT_APP_PASS)) {
      pass = prompt("Invalid Password! | Please Enter your password");
    }
    localStorage.setItem("love-pass", JSON.stringify({ pass: pass }));
  }

  return (
    <div className="container">
      <Header />
      <div className="main">
        <Aside
          arr={arr}
          setPlay={setPlay}
          setArr={setArr}
          generateRandomNumber={generateRandomNumber}
        />
        <VideoPlayer play={play} />
      </div>
    </div>
  );
}

export default App;
