import React from "react";

const Header = () => {
  // console.log("Header");

  return (
    <header>
      <p>Love Is Fun</p>
    </header>
  );
};

export default Header;
