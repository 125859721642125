import React from "react";

const VideoPlayer = ({ play }) => {
  // console.log("VideoPlayer");

  let newSrc = `https://4kporn.xxx/embed/${play}?autoplay=0`;
  // if (play === 19807) {
  //   newSrc = `https://4kporn.xxx/embed/${play}?autoplay=0`;
  // }

  return (
    <div>
      <iframe
        className="mainVideo"
        src={newSrc}
        allowFullScreen
        allow="autoplay"
        title="Mail Video"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
