import React from "react";

const Aside = ({ arr, setPlay, setArr, generateRandomNumber }) => {
  // console.log("Aside");

  const handleClick = (ele) => {
    const newArr = arr.map((n) => {
      if (n === ele) {
        return generateRandomNumber();
      } else {
        return n;
      }
    });
    setPlay(ele);
    setArr(newArr);
  };

  return (
    <div className="options">
      {arr.map((ele, idx) => {
        const newSrc = `https://4kporn.xxx/embed/${ele}?autoplay=0`;
        return (
          <div className="clickable-parent" key={idx}>
            <iframe className="optionSelection" src={newSrc} title="Options" />
            <div
              className="clickable"
              onClick={(e) => {
                handleClick(ele);
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default Aside;
